import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';

const requireBlogPosts = require.context('../blogs', false, /\.js$/);

const BlogPost = () => {
    const { blogId } = useParams();
    const [BlogComponent, setBlogComponent] = useState(null);

    useEffect(() => {
        const loadBlog = async () => {
            const blogFile = requireBlogPosts.keys().find((fileName) =>
                fileName.toLowerCase().includes(blogId)
            );
            const blogModule = await requireBlogPosts(blogFile);
            setBlogComponent(() => blogModule.default);
            window.scrollTo(0, 0);
        };

        loadBlog();
    }, [blogId]);

    if (!BlogComponent){
        return;
    }

    return <BlogComponent isPreview={false} />;
};

export default BlogPost;
