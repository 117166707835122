import React from 'react';
import MapboxGL from 'mapbox-gl';
import {Box, useMediaQuery} from '@mui/material';
import 'mapbox-gl/dist/mapbox-gl.css';
import { scrollIntoView } from "seamless-scroll-polyfill";
import Typography from "@mui/material/Typography";


MapboxGL.accessToken = 'pk.eyJ1IjoiMjUyNCIsImEiOiJja3ZsMzV1d3gwZ3NjMnVxbnFybnN0Z2VpIn0.KQ5NLubZ7-oAfEJgSMGxXw';

const createHighLightContent = (img, caption, isMobile) => {

    return (
        <Box key={img.img} sx={{width: img.direction === 'horizontal' ? (isMobile? '100%' : '80%') : (isMobile? '67%' : '53%'), margin: '0 auto', paddingTop: '20px'}}>
            <Box
                component="img"
                src={`${process.env.PUBLIC_URL}/home/${img.img}.jpg`}
                sx={{width: '100%'}}>
            </Box>
            <Typography variant="caption" sx={{color: '#464646', fontSize: 15}}>
                {caption}
            </Typography>
        </Box>
    );
};

const MapBox = ({ photos, setHighlights, highlightRef, isMobile }) => {
    const mapContainer = React.useRef(null);

    React.useEffect(() => {
        const map = new MapboxGL.Map({
            container: mapContainer.current,
            style: 'mapbox://styles/2524/cm1e1yjrb012201pd0hk50akm',
            center: isMobile ? [-109.615, 37.804] : [-107.415, 37.804],
            zoom: isMobile ? 3 : 4.08,
            minZoom: isMobile ? 3 : 4.08,
            maxZoom: isMobile ? 3 : 4.08,
            attributionControl: false,
            maxBounds: [
                [-129.95348146347553, 26.75588323115943], // sw
                [-84.87651853652498, 47.417830848239674]  // ne
            ]
        });

        Object.entries(photos).forEach(([key, value]) => {
            const el = document.createElement('div');
            el.className = 'marker';

            const marker = new MapboxGL.Marker(el, {anchor: "bottom"})
                .setLngLat([value.coordinate[0], value.coordinate[1]])
                .addTo(map);
            marker.getElement().addEventListener('click', () => {
                // ReactDOM.flushSync(() => {
                const newHighlights = (
                    <Box ref={highlightRef} sx={{paddingTop: '5%', textAlign: 'center', display: 'block', minHeight: '90vh'}}>
                        <Typography variant="h5" sx={{color: '#464646'}}>
                            Highlights
                        </Typography>
                        {value.image.map((img) =>
                            createHighLightContent(img, `${key}, on ${img.type}`, isMobile)
                        )}
                    </Box>
                );
                setHighlights(newHighlights);
                // });
            });
        });

        return () => map.remove();
    }, [photos, setHighlights, highlightRef, isMobile]);

    return (
        <Box
            ref={mapContainer}
            sx={{ width: '100%', height: '85vh' }}
        />
    );
};

function Home({photos}) {
    const [highlights, setHighlights] = React.useState([]);
    const highlightRef = React.useRef(null);

    const isMobile = useMediaQuery('(max-width:768px)');

    React.useEffect(() => {
        if (highlights && highlightRef.current) {
            scrollIntoView(highlightRef.current, {
                behavior: "smooth",
                block: "start",
            },{
                duration: 500
            });
        }
    }, [highlights]);

    return (
        <Box className="content" sx={{width: isMobile ? '90%' : '80%', margin: '0 auto'}}>
            <MapBox photos={photos} setHighlights={setHighlights} highlightRef={highlightRef} isMobile={isMobile}/>
            {/*<Box ref={highlightRef} sx={{paddingTop: '5%', textAlign: 'center', display: 'none'}}>*/}
            {/*    <Typography variant="h5" sx={{color: '#464646'}}>*/}
            {/*        Highlights*/}
            {/*    </Typography>*/}
            {highlights}
            {/*<Box sx={{width: '80%', margin: '0 auto', paddingTop: '2%'}}>*/}
            {/*    <Box*/}
            {/*        component="img"*/}
            {/*        src={`${process.env.PUBLIC_URL}/selfies/main.jpg`}*/}
            {/*        sx={{width: '100%'}}>*/}
            {/*    </Box>*/}
            {/*    <Typography variant="caption" sx={{color: '#464646', fontSize: 15}}>*/}
            {/*        this is caption placeholder*/}
            {/*    </Typography>*/}
            {/*</Box>*/}
            {/*</Box>*/}
        </Box>
    );
}

export default Home;
