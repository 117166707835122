import {Typography, useMediaQuery} from '@mui/material';
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import React from "react";

const Hi = ({ isPreview }) => {
    const isMobile = useMediaQuery('(max-width:768px)');

    return(
        <Box>
            {isPreview &&
            <Box className="previewCard">
                <Grid container style={{height: '100%'}}>
                    <Grid item xs={4}>
                        <Box
                            component="img"
                            className='previewImage'
                            src={`${process.env.PUBLIC_URL}/blog/hi/0.jpg`}
                        />
                    </Grid>
                    <Grid item xs={8} sx={{padding: 2}} className='previewText'>
                        <Typography variant={isMobile ? "h6":"h5"} className='previewTitle'>Hi There!</Typography>
                        <Typography variant="body2" className='previewDate' color="textSecondary">
                            Sep 25, 2024
                        </Typography>
                        <Typography variant="body1" className='previewContent'>
                            How it all started
                        </Typography>
                    </Grid>
                </Grid>
            </Box>
            }
            {!isPreview &&
            <Box className="content" sx={{width: isMobile ? '90%' : '80%', margin: '0 auto'}}>
                <Typography variant={isMobile ? "h4" : "h3"} className='blogTitle'>Hi There!</Typography>
                <Typography color="textSecondary" className='blogDate' gutterBottom>
                    September 20, 2024
                </Typography>
                <Box className='bolgContent'>
                    <Box className='blogText'>
                        <Typography variant="body1" className='blogParagraph'>
                            It all started with hanging around in Urban Outfitter before my trip to Alaska back in March
                            2023. At checkout, I came across the Kodak FunSaver disposable camera. I had been using a
                            Polaroid, but its limitations were starting to frustrate me. After a few trips with
                            disappointing photos, I was ready to try something new. So when I saw this simple, cheap film
                            camera, I thought, "This has to be better than a Polaroid—why not?" I grabbed two.
                        </Typography>
                        <Typography variant="body1" className='blogParagraph'>
                            As expected, the photos turned out way better than the Polaroids, and I was thrilled. I love
                            that you have to wait and develop the film before seeing the results. All the effort built up
                            into pure excitement when I finally got to see the photos. Here are a few of my favorites from
                            back then.
                        </Typography>
                    </Box>
                    <Box
                        component="img"
                        className='blogImage'
                        src={`${process.env.PUBLIC_URL}/blog/hi/1.jpg`}
                    />
                    <Typography variant="caption" className='blogCaption'>
                        Ice-fishing in Fairbanks
                    </Typography>
                    <Box
                        component="img"
                        className='blogImage'
                        src={`${process.env.PUBLIC_URL}/blog/hi/2.jpg`}
                    />
                    <Typography variant="caption" className='blogCaption'>
                        Sunset on the ice-fishing lake
                    </Typography>
                    <Box className='blogText'>
                        <Typography variant="body1" className='blogParagraph'>
                            I have to mention that since the first time I shot with film cameras, I sent them to The
                            Darkroom to develop. They are very reliable and professional, and their updates of "We received
                            your order", "Your photos are being uploaded" and "Your photos are on the way" are some of the
                            best emails I can expect. I've trusted them with my films ever since.
                        </Typography>
                    </Box>
                    <Box
                        component="img"
                        className='blogImage'
                        src={`${process.env.PUBLIC_URL}/blog/hi/3.jpg`}
                    />
                    <Typography variant="caption" className='blogCaption'>
                        Sunset in downtown Fairbanks
                    </Typography>
                    <Box
                        component="img"
                        className='blogImage'
                        src={`${process.env.PUBLIC_URL}/blog/hi/4.jpg`}
                    />
                    <Typography variant="caption" className='blogCaption'>
                        On the way of cross-country skiing
                    </Typography>
                    <Box className='blogText'>
                        <Typography variant="body1" className='blogParagraph'>
                            I do see they are not that good and have some serious problems now, but switching from
                            Polaroids, these results are already huge upgrades and absolutely encourage me to keep going.
                        </Typography>
                    </Box>
                    <Box
                        component="img"
                        className='blogImage'
                        src={`${process.env.PUBLIC_URL}/blog/hi/5.jpg`}
                    />
                    <Typography variant="caption" className='blogCaption'>
                        Took with Canon Sure Shot in Lassen Volcanic National Park
                    </Typography>
                    <Box className='blogText'>
                        <Typography variant="body1" className='blogParagraph'>
                            I realized film cameras could work. After months of shooting with point-and-shoots, I decided it
                            was time to make some upgrades. I’ve got to give a shoutout to Houston for having such a great
                            local camera store—Houston Camera Exchange. Joe was there for me. He picked out a fully manual
                            Nikon FE for me, along with a 35-105mm lens. He walked me through everything, explaining how
                            every button and number works. He even took the time to show me how to properly switch lenses to
                            avoid dust, then watched me practice. Before leaving, I took a picture of him—the man who taught
                            me all. He said it's his responsibility to pass down the knowledge as much as possible before
                            he's gone.
                        </Typography>
                    </Box>
                    <Box
                        component="img"
                        className='blogImage'
                        src={`${process.env.PUBLIC_URL}/blog/hi/6.jpg`}
                    />
                    <Typography variant="caption" className='blogCaption'>
                        Joe, the hero
                    </Typography>
                    <Box className='blogText'>
                        <Typography variant="body1" className='blogParagraph'>
                            And that’s where my adventures really began.
                        </Typography>
                        <Typography variant="body1" className='blogParagraph'>
                            I've had that Polaroid for 6 years now and thanks to it, I've captured so many wonderful moments
                            with friends. I still keep it with me on every trip, even though I barely use it. Just in case I
                            meet someone special, I'd love to snap an irreplaceable picture with them and hold that memory
                            in my pocket.
                        </Typography>
                        <Typography variant="body1" className='blogParagraph'>
                            The idea for this website came from developing the films I shot on my recent trip to Oregon. The
                            photos turned out so beautifully, and after holding cameras for a while now, I finally have
                            something I’m proud of. I believe that pictures themselves don’t hold meanings: it’s the stories
                            behind them that matter. The person who captures the moment gives meaning to the photos.
                            Sometimes, I want to share those behind-the-scenes stories. I’ll also share some of my trip
                            plans because I don’t always aim for touristy places. I’d be excited if my stories could inspire
                            you even a little.
                        </Typography>
                    </Box>
                </Box>
            </Box>
            }
        </Box>
    )
};

export default Hi;
